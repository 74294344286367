@import '../../styles/variables';

.success {
  padding: 0 10px 0 8px;
  box-shadow: 0 8px 32px -8px rgba(62, 127, 36, 0.23);
  border-radius: 8px;
  background: $tree-green;
  min-width: calc(100% + 34px);
  position: relative;

  svg {
    margin-right: 6px;
    margin-bottom: auto;
    min-width: 24px;
  }
  & > div:first-child {
    max-width: 508px;
  }
}

.error {
  padding: 0 10px 0 8px;
  box-shadow: 0 8px 32px -8px rgba(190, 10, 20, 0.23);
  border-radius: 6px;
  background: $warning-red;
  min-width: calc(100% + 34px);
  position: relative;

  svg {
    margin-right: 8px;
    margin-bottom: auto;
    min-width: 24px;
  }
  & > div:first-child {
    max-width: 508px;
  }
}
