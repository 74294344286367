@import 'src/styles/variables';

.button_root {
  position: absolute;
  right: 0;
  top: 0;
  color: $white;
  border-left: 1px solid $white;
  padding-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
  height: calc(100% - 16px);
  display: flex;
  align-items: center;
  & svg {
    margin: 0 !important;
  }
}
