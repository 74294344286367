@import 'src/styles/variables';

.spinnerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

@keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-name: rotate-forever;
  animation-timing-function: linear;
  width: 30px;
  height: 30px;
  border: 5px solid $main-brand;
  border-right-color: transparent;
  border-radius: 50%;
}

.hidden {
  display: none;
}
