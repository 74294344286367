$scaling-factor:8px;

$main-brand: #ff3c28;
$main-light:  #fce1d2;
$main-hover: #e63e24;

$primary: $main-brand;
$secondary: #0a192d;
$secondary-disabled: #c2c6cb;
$secondary-hover: #475261;
$off-white: #fff8dd;
$curry-light: #fcebd1;
$light-purple: #E0E7F1;

$gray:#ebebeb;
$gray-darker: #E0E0E0;
$grayLight:#dce6eb;
$gray-lighter: #f7f7f7;

$light:#F5F5F5;

$wild-sand: #f6f6f6;
$desert-storm:#f0efee;
$cod-gray:#131313;
$chill-gray: #858585;
$citrine-white: #fafad6;
$tree-green: #3e7f24;
$nightblue-light: #838F9E;

$pot-pourri: #EFD9D1;
$lightgrey-lightest: #F4F7F9;

$almond: #EFD9D1;
$water-blue: #1975d0;
$nature-green: #2eaf9a;
$azure-adiance : #147BFC;
$gallery: #eaeaea;
$silver: #bfbfbf;
$pickled-blueWood: #646c84;
$alto: #cecece;
$purplish-red: #b50c5b;
$reddish-orange: #ec5b1d;
$light-peach: #efd9d0;
$punch: #dc3645;
$red-punch:#dc3645;
$green-fern:#7eb973;
$very-light-green: #cef9c6;
$light-orange: #fd8c4c;
$rose: #efc8cd;
$night-blue: #081f3e;
$light-blue-grey: #46576F;
$elm: #24717f;
$aqua-haze: #f4f7f9;
$light-grey: #EAF0F3;

$seaweed-green: #F0F5ED;

$white: #fff;
$black: #252525;

$iron: #646C84;
$light-iron: #C1C7CF;
$titanium:#E0E7F1;

$green: #3E7F24;
$success: #31B267;
$red: #d21e4a;
$info: #199EB3;
$warning: #856404;
$disabled: #b2b2b2;

$hover-red: rgba(210, 30, 74, 0.8);
$dark-red: #be0a14;
$warning-red: #BE0A13;

$scorpion: #595959;
$charcoal: #1d201c;
$charcoal-grey: #505151;
$mystic: #e9eff2;

$ice-kings-beard: #DCE6EB;
$funnier-than-24: #252525;

$rose-lightest: #FAEEF0;
$rose-light: #F5DEE1;
$rose-mid: #F3D3D7;

$light-teal: #E0F1F0;

$heavy-shadow: 0 8px 32px -8px rgba(37, 37, 37, 0.23);
$light-grey-shadow: 0 8px 16px -8px $grayLight;
$light-white-shadow: 0 8px 16px -12px rgba(37, 37, 37, 0.23);

$orange: #F4B046;

$shiny-green: #EEFAF1;

$curry: #F9D7A3;